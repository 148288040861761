body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #1E5FE1;
  --secondary-color: #B9EBC3;
  --dark-color: #1E1E1E;
  --medium-dark-color: #A59B91;
  --light-dark-color: #CDC3B9;
  --light-color: #F5EBE1;
  --grey-color: #858798;
}

::-webkit-scrollbar {
  width: 20px;
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 10px;
  border: 7px solid white;
}